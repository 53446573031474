import React from 'react';
import Ads from "../Ads"

function LandingSidebar() {
    return (
        <div>
            <Ads />
            <hr />
        </div>
    )
}

export default LandingSidebar;
