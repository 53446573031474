import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function ResetPassword() {
    return (
        <div id="resetPassword" className="resetPassword vh-100">
        <Container>
        <Row className="mt-5 mx-auto text-center">
            <Col className="">
                hi
            </Col>
        </Row>
        </Container>
        </div>
    )
}

export default ResetPassword
